<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("users.title", 2) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn color="buttons" @click="showAddItemDialog" :loading="loading">
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("users.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" :name="$tc('users.title', 1)" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="users"
            :showEdit="true"
            :showDelete="true"
            :showAddAgencies="true"
            @activeChange="switchActiveChanged"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="addItemDialog"
      persistent
      v-model="addItemDialog"
      max-width="600px"
      scrollable
    >
      <add-item
        :selectedItem="selectedItem"
        @success="userCreated"
        @cancel="addItemDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="addAgenciesDialog"
      persistent
      v-model="addAgenciesDialog"
      max-width="700px"
    >
      <add-agencies
        :selectedItem="selectedItem"
        @success="handleSuccess($tc('users.agencies_added'))"
        @cancel="addAgenciesDialog = false"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <delete-user
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import addItem from "./add-user";
import deleteUser from "@/components/delete";
import filesTable from "@/components/files-table";
import AddAgencies from "./add-agencies.vue";
import AddApps from "./add-app.vue";

export default {
  name: "Users",
  components: {
    addItem,
    deleteUser,
    filesTable,
    AddAgencies,
    AddApps,
  },
  data() {
    return {
      users: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addItemDialog: false,
      selectedItem: null,
      viewImageDialog: false,
      deleteDialog: false,
      addAgenciesDialog: false,
      dragging: false,
      files: [],
      multipleFilesDialog: false,
      headers: [
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
        },

        {
          text: this.$tc("headers.email"),
          value: "email",
          width: "150px",
        },
        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          text: this.$tc("headers.role"),
          value: "role",
        },
        {
          text: this.$tc("headers.assigned_agency"),
          value: "agencies",
          align: "center",
        },
        {
          text: this.$tc("headers.active"),
          value: "active",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  watch: {
    group(data) {
      this.getUsers();
    },
  },

  computed: {
    ...mapState(["group", "user"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    showAddItemDialog() {
      this.selectedItem = null;
      this.addItemDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "edit":
          this.editItem(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
        case "add_agencies":
          this.addAgencies(payload.item);
          break;
      }
    },
    editItem(item) {
      this.selectedItem = item;
      this.addItemDialog = true;
    },
    addAgencies(item) {
      this.selectedItem = item;
      this.addAgenciesDialog = true;
    },

    switchActiveChanged(data) {
      this.loading = true;
      let payload = {
        userId: data.id,
        active: data.active,
      };
      var httpUsersUpdate = fb.functions().httpsCallable("httpUsersUpdate");
      httpUsersUpdate(payload)
        .then((result) => {
          this.loading = false;

          this.snackbarText = data.active
            ? this.$tc("users.now_active")
            : this.$tc("users.now_inactive");
          this.snackbar = true;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    handleSuccess(message) {
      this.addAgenciesDialog = false;
      this.snackbarText = message;
      this.snackbar = true;
      this.getUsers();
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;

      const payload = {
        userId: this.selectedItem.id,
      };

      var httpUsersDelete = fb.functions().httpsCallable("httpUsersDelete");
      httpUsersDelete(payload)
        .then((result) => {
          this.deleteDialog = false;
          this.$refs.deleteComponent.loading = false;
          this.snackbarText = this.$tc("users.deleted");
          this.snackbar = true;
          this.getUsers();
        })
        .catch((err) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    userCreated(data) {
      this.snackbarText = this.selectedItem
        ? this.$tc("users.updated")
        : this.$tc("users.created");
      this.snackbar = true;
      this.addItemDialog = false;
      this.selectedItem = null;
    },

    async getUsers() {
      this.loading = true;
      this.users = [];

      for (let i = 0; i < this.group.users.length; i = i + 10) {
        let tempArray = this.group.users.slice(i, i + 10);
        await db
          .collection(`users`)
          .where(fb.firestore.FieldPath.documentId(), "in", tempArray)
          .where("deleted", "==", false)
          .get()
          .then((response) => {
            response.forEach((item) => {
              let user = item.data();
              user.id = item.id;
              if (this.user.uid != user.id) this.users.push(user);
            });
          });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.cleanSearch();
    this.getUsers();
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>