<template>
  <v-card class="d-flex flex-column">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ selectedItem ? $tc("dialog.edit") : $tc("dialog.add") }}
        {{ $tc("users.title", 1) }}</span
      >
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        :loading="loading"
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-5">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="user.name"
              :rules="[rules.required]"
              :label="$tc('users.username')"
              clearable
              :loading="loading"
              rounded
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="user.email"
              :label="$tc('users.email')"
              clearable
              :loading="loading"
              :rules="[rules.email]"
              rounded
              filled
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-select
              :items="roles"
              item-text="name"
              rounded
              filled
              required
              :rules="[rules.required]"
              item-value="value"
              :loading="loading"
              v-model="user.role"
              :label="$tc('users.role')"
            ></v-select>
          </v-col>

          <v-col v-if="!selectedItem" cols="10" sm="10" md="10">
            <v-text-field
              v-model="user.password"
              :label="$tc('users.password')"
              clearable
              :type="showPassword ? 'text' : 'password'"
              required
              autocomplete="off"
              :loading="loading"
              :rules="[rules.required, rules.min]"
              rounded
              filled
            ></v-text-field>
          </v-col>
          <v-col v-if="!selectedItem" class="mt-3" cols="2" sm="2" md="2">
            <v-btn @click="showPassword = !showPassword" icon>
              <v-icon>
                {{ showPassword ? "fas fa-eye-slash" : "fas fa-eye" }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="!selectedItem" cols="12" sm="12" md="12">
            <v-text-field
              v-model="user.confirmPassword"
              :label="$tc('users.confirm_password')"
              clearable
              :loading="loading"
              autocomplete="off"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.required, rules.min]"
              rounded
              filled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="6" sm="6" md="6">
            <v-switch
              v-model="user.active"
              :loading="loading"
              :label="$tc('users.active')"
            ></v-switch>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row justify="end">
              <v-btn
                class="mt-7"
                @click="selectedItem ? update() : save()"
                :loading="loading"
                color="buttons"
                >{{ $tc("button.save") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-user",
  props: ["selectedItem", "admin"],
  data() {
    return {
      user: {
        active: true,
      },
      snackbarText: "",
      snackbar: false,
      showPassword: false,
      loading: false,
      roles: [
        { name: this.$tc("roles.super_user"), value: "sa" },
        { name: this.$tc("roles.supervisor"), value: "su" },
        { name: this.$tc("roles.editor"), value: "ed" },
        { name: this.$tc("roles.directory_editor"), value: "de" },
      ],
      rules: {
        required: (value) => !!value || this.$tc("rules.required"),
        positive: (value) => value > 0 || this.$tc("rules.positive"),
        min: (v) => (v && v.length >= 6) || this.$tc("rules.minimun"),
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || this.$tc("rules.invalid_email");
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
  },

  methods: {
    validateUpdateUser() {
      return new Promise((resolve) => {
        let isValidForm = true;

        let requiredFields = [
          { field: "name", text: this.$tc("users.name") },
          { field: "email", text: this.$tc("users.email") },
          { field: "role", text: this.$tc("users.role") },
        ];

        for (let i = 0; i < requiredFields.length; i++) {
          const field = requiredFields[i].field;
          if (!this.user[field]) {
            this.snackbarText = this.$tc(
              "users.required_child",
              requiredFields[i].text,
              { field: requiredFields[i].text }
            );
            this.snackbar = true;
            isValidForm = false;
            break;
          }
        }

        if (isValidForm) return resolve(true);
        else return resolve(false);
      });
    },
    validateUser() {
      return new Promise((resolve) => {
        let isValidForm = true;

        let requiredFields = [
          { field: "name", text: this.$tc("users.name") },
          { field: "email", text: this.$tc("users.email") },
          { field: "role", text: this.$tc("users.role") },
          { field: "password", text: this.$tc("users.password") },
          {
            field: "confirmPassword",
            text: this.$tc("users.confirm_password"),
          },
        ];

        for (let i = 0; i < requiredFields.length; i++) {
          const field = requiredFields[i].field;
          if (!this.user[field]) {
            this.snackbarText = this.$tc(
              "users.required_child",
              requiredFields[i].text,
              { field: requiredFields[i].text }
            );
            this.snackbar = true;
            isValidForm = false;
            break;
          }
        }

        if (this.user.password && this.user.password.length < 6) {
          this.snackbarText = this.$tc("users.weak_password");
          this.snackbar = true;
          isValidForm = false;
        }

        if (this.user.password != this.user.confirmPassword) {
          this.snackbarText = this.$tc("users.diferent_password");
          this.snackbar = true;
          isValidForm = false;
        }

        if (isValidForm) return resolve(true);
        else return resolve(false);
      });
    },
    async save() {
      let validation = await this.validateUser();

      if (validation) {
        this.loading = true;
        var httpUsersCreate = fb
          .functions()
          .httpsCallable(
            this.admin ? "httpAdministrationAddUser" : "httpUsersCreate"
          );

        httpUsersCreate(this.user)
          .then((result) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            switch (err.code) {
              case "already-exists":
                this.snackbarText = this.$tc("users.email_used");
                this.snackbar = true;
                break;

              default:
                this.snackbarText = this.$tc("messages.unknown");
                this.snackbar = true;
                break;
            }
            this.loading = false;
          });
      }
    },

    async update() {
      const validation = await this.validateUpdateUser;

      if (validation) {
        const payload = {
          name: this.user.name,
          email: this.user.email,
          active: this.user.active,
          role: this.user.role,
          userId: this.user.id,
        };
        this.loading = true;
        var httpUsersUpdate = fb.functions().httpsCallable("httpUsersUpdate");
        httpUsersUpdate(payload)
          .then((result) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            switch (err.code) {
              case "already-exists":
                this.snackbarText = this.$tc("users.email_used");
                this.snackbar = true;
                break;

              default:
                this.snackbarText = this.$tc("messages.unknown");
                this.snackbar = true;
                break;
            }
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.user = JSON.parse(JSON.stringify(this.selectedItem));
    }
  },
};
</script>
