<template>
  <v-container>
    <v-row>
      <v-col cols="5" sm="5" md="5">
        <span class="font-weight-bold ml-1">{{ leftSideTitle }} ({{leftSideItems.length}})</span>
        <div class="main">
          <div class="search">
            <v-checkbox
              @change="selectAll($event, 'left')"
              :loading="loading"
            ></v-checkbox>
            <v-text-field
              hide-details
              :loading="loading"
              v-model="leftSearch"
              rounded
              filled
              :height="25"
              style="margin-right: 15px"
              :label="$tc('button.search')"
            />
          </div>
          <div style="margin-top: 70px">
            <v-checkbox
              class="ml-3"
              :loading="loading"
              v-for="(item, index) of leftSideItems"
              :key="index"
              hide-details
              v-model="item.checked"
            >
              <template v-slot:label>
                <div>
                  {{ item.name }}
                  <br>
                  <small v-if="item.email" class="font-weight-bold">{{
                    item.email
                  }}</small>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
      <v-col class="buttons-actions" cols="2" sm="2" md="2">
        <v-row class="ma-0 pa-0">
          <v-col class="buttons-actions" cols="12" sm="12" md="12">
            <v-btn
              :loading="loading"
              @click="toRight"
              fab
              dark
              small
              color="buttons"
            >
              <v-icon dark> fas fa-arrow-right </v-icon>
            </v-btn>
          </v-col>
          <v-col class="buttons-actions" cols="12" sm="12" md="12">
            <v-btn
              :loading="loading"
              @click="toLeft"
              class="mt-2"
              fab
              dark
              small
              color="buttons"
            >
              <v-icon dark> fas fa-arrow-left </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5" sm="5" md="5">
        <span class="font-weight-bold ml-1">{{ rightSideTitle }} ({{rightSideItems.length}})</span>
        <div class="main">
          <div class="search">
            <v-checkbox
              @change="selectAll($event, 'right')"
              :loading="loading"
            ></v-checkbox>
            <v-text-field
              hide-details
              :loading="loading"
              rounded
              :height="25"
              filled
              style="margin-right: 15px"
              v-model="rightSearch"
              :label="$tc('button.search')"
            />
          </div>
          <div style="margin-top: 70px">
            <v-checkbox
              class="ml-3"
              :loading="loading"
              v-for="(item, index) of rightSideItems"
              :key="index"
              hide-details
              v-model="item.checked"
            >
              <template v-slot:label>
                <div>
                  {{ item.name }}
                  <br>
                  <small v-if="item.email" class="font-weight-bold">{{
                    item.email
                  }}</small>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "transfer",
  props: [
    "rightSource",
    "leftSource",
    "leftSideTitle",
    "rightSideTitle",
    "loading",
  ],
  data() {
    return {
      left: [],
      right: [],
      leftSearch: "",
      rightSearch: "",
      completeRightSource: [],
    };
  },
  computed: {
    leftSideItems() {
      if (this.leftSearch) {
        return this.left.filter((item) =>
          item.name.toLowerCase().includes(this.leftSearch.toLowerCase())
        );
      } else {
        return this.left;
      }
    },
    rightSideItems() {
      if (this.rightSearch) {
        return this.completeRightSource.filter((item) =>
          item.name.toLowerCase().includes(this.rightSearch.toLowerCase())
        );
      } else {
        return this.completeRightSource;
      }
    },
  },
  methods: {
    changed() {
      let itemAddedKey = this.rightSideItems.map((item) => {
        return item[".key"];
      });
      this.$emit("changed", itemAddedKey);
    },
    selectAll($event, side) {
      if (side == "left") {
        this.left = this.left.map((item) => {
          item.checked = $event;
          return item;
        });
      } else {
        this.completeRightSource = this.completeRightSource.map((item) => {
          item.checked = $event;
          return item;
        });
      }
    },
    toRight() {
      let selectedItems = this.left.filter((item) => item.checked);

      this.left = this.left.filter((item) => !item.checked);

      this.completeRightSource.push(...selectedItems);

      this.completeRightSource = this.completeRightSource.map((item) => {
        item.checked = false;
        return item;
      });

      this.changed();
    },

    toLeft() {
      let selectedItems = this.completeRightSource.filter(
        (item) => item.checked
      );
      this.completeRightSource = this.completeRightSource.filter(
        (item) => !item.checked
      );

      this.left.push(...selectedItems);

      this.left = this.left.map((item) => {
        item.checked = false;
        return item;
      });
      this.changed();
    },
  },
  mounted() {
    this.left = JSON.parse(JSON.stringify(this.leftSource));
    this.right = JSON.parse(JSON.stringify(this.rightSource));
    this.completeRightSource = this.left.filter((item) =>
      this.right.includes(item[".key"])
    );
    this.left = this.left.filter((item) => !this.right.includes(item[".key"]));
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgba(156, 156, 156, 0.733);
  position: relative;
  margin-top: 10px;
}
.buttons-actions {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.search {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
</style>