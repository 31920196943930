<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{$tc('assign_agencies.title')}}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          :loading="loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col v-if="!waiting" cols="12" sm="12" md="12">
              <transfer
                :rightSideTitle="$tc('assign_agencies.added_agencies')"
                :leftSideTitle="$tc('assign_agencies.all_agencies')"
                @changed="agenciesChanged"
                :loading="loading"
                :rightSource="selectedItem.agencies || []"
                :leftSource="agencies"
              />
            </v-col>
            <v-col v-else cols="12" sm="6" md="6">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-col>
            <v-col v-if="waiting" cols="12" sm="6" md="6">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn :loading="loading" color="buttons" @click="updateUser"
                  >{{$tc('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import transfer from "@/components/transfer";
import { mapState } from 'vuex';

export default {
  name: "add-agencies",
  props: ["selectedItem"],
  components: {
    transfer,
  },
  data() {
    return {
      loading: true,
      agencies: [],
      agenciesAdded: [],
      waiting : true,
      snackbarText : '',
      snackbar : false,
    };
  },
  computed : {
    ...mapState(['groupId']),
  },
  methods: {
    agenciesChanged(data) {
      this.agenciesAdded = data;
    },
    updateUser() {
      this.loading = true;
      const payload = {
        userId: this.selectedItem.id,
        agencies: this.agenciesAdded,
        active: this.selectedItem.active,
      };

      var httpUsersUpdate = fb.functions().httpsCallable("httpUsersUpdate");
      httpUsersUpdate(payload)
        .then((result) => {
          this.loading = false;
          this.$emit('success')
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText =
            this.$tc('messages.unknown');
          this.snackbar = true;
        });
    },
  },
  async mounted() {
    this.$binding(
      "agencies",
      db.collection("agencies").where("groupId", "==", this.groupId)
    ).then((res) => {
        this.waiting = false
        this.loading = false;
    });
  },
};
</script>